.k-animation-container.k-animation-container-relative.k-animation-container-shown {
    z-index: 13000000 !important;
}

.k-animation-container.k-animation-container-relative {
    z-index: 13000000 !important;
}

.k-animation-container.k-animation-container-relative.navigation-dropdown-popup {
    z-index: 13000000 !important;
}

.k-animation-container.k-animation-container-fixed.k-animation-container-shown {
    z-index: 13000000 !important;
}

.k-animation-container {
    z-index: 13000000 !important;
}